<template>
  <div class="content">
    <van-nav-bar title="工作台" right-text="退出" @click-right="onClickRight" />
    <van-loading type="spinner" v-if="show" vertical>加载中...</van-loading>
    <div class="card" v-for="(item, index) in menulist" :key="index">
      <div class="title">{{ item.parentName }}</div>
      <van-grid :column-num="4">
        <van-grid-item
          v-for="(i, j) in item.children"
          :key="j"
          :text="i.title"
          @click="go(i)"
        >
          <template slot="icon">
            <div class="iconBox">
              <svg class="icon" aria-hidden="true">
                <use :xlink:href="i.icon"></use>
              </svg>
            </div>
          </template>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>

<script>
import svgIcon from "@/components/SvgIcon";
import { getMenuList } from "@/api/index";
import { loadCSS } from "@/api/base/menuBackground";
import { getStore, setStore } from "@/utils/localStorage";
export default {
  data() {
    return {
      menulist: [],
      list: [],
      show: true,
    };
  },
  component: {
    svgIcon,
  },

  created() {
    localStorage.removeItem("routerHistory");
    loadCSS(localStorage.getItem("qwxCss"), function () {});
    this.getMenu();
  },
  methods: {
    getMenu() {
      getMenuList()
        .then((res) => {
          this.show = false;
          this.menulist = res.data.data;
          console.log(this.menulist, "this.menulist");
        })
        .catch((error) => {
          this.show = false;
          console.log(error);
        });
    },

    go(val) {
      //  localStorage.removeItem("routerHistory");
      let token = getStore({ name: "token" });
      // localStorage.setItem('token',token)
      if (val.path.startsWith("http")) {
        if (val.path.split(":")[0] == "https") {
          window.open(`${val.path}?token=${token}`, "_blank");
        } else {
          window.open(val.path, "_blank");
        }
      } else {
        this.$router.push(val.path);
      }
    },

    onClickRight() {
      this.$router.replace("/login");
    },
  },
};
</script>
<style scoped>
.van-hairline--top::after {
  border: none;
}
.icon {
  width: 1em;
  height: 1.5em;
  vertical-align: middle;
  fill: currentColor;
  overflow: hidden;
  font-size: 28px;
  text-align: center;
}
.card {
  margin: 20px 10px;
}
.card:first-child {
  margin: 0 10px;
}

.title {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  padding-left: 10px;
  margin-top: 10px;
  color: #999;
  /* border: 1.8px solid rgba(245,247,248);
  border-bottom: none;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
  box-sizing: border-box; */
}
.van-grid-item {
  color: #333;
  font-size: 18px;
}

.van-loading {
  left: 50%;
  transform: translateX(-50%);
}

/* .box {
  margin: 30px auto;
} */

/* ul {
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #f7f8f9;
} */
/* ul > li {
  width: 33.3%;
  height: 80px;
  background-color: aqua;
  border: 1px solid #f7f8f9;
} */
/* ul {
  border-top: none;
  border-right: none;
} */
/* ul:nth-child(2) li {
  border-left: none;
  border-bottom: none;
} */

/* li {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
} */
/* li > span {
  width: 100%;
  font-size: 14px;
  text-align: center;
  padding-top: 10px;
} */
</style>
