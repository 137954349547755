<template>
  <svg class="icon" aria-hidden="true" :style="{fontSize: `${font}px`}">
    <use :xlink:href="`#${type}`"></use>
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    type: String,
    font: [String, Number],
  },
};
</script>
